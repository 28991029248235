<template>
  <c-grid
    width="100%"
    :template-columns="['repeat(6, 1fr)']"
    :gap="['6px']"
    margin-bottom="24px"
  >
    <c-box
      v-for="step in steps"
      :key="step.value"
      border-radius="8px"
      :_hover="{
        backgroundColor: '#f2f2f2',
      }"
      :cursor="step?.disabled ? 'not-allowed' : step?.onClick ? 'pointer' : 'default'"
      @click="step?.onClick()"
    >
      <!-- Item With Tooltip-->
      <c-tooltip
        v-if="step?.tooltip"
        :label="step?.tooltip"
        placement="bottom"
      >
        <c-flex
          flex-direction="column"
          justify-content="space-between"
        >
          <c-text
            font-size="16px"
            font-weight="500"
            :color="constants.labelColor?.[step.color] || constants.labelColor.neutral"
            text-align="center"
            margin-bottom="12px"
          >
            {{ step.label }}
          </c-text>
          <c-box
            width="100%"
            height="8px"
            border-radius="20px"
            :background-color="constants.stepColor?.[step.color] || constants.stepColor.neutral"
          />
        </c-flex>
      </c-tooltip>

      <!-- Item Without Tooltip-->
      <c-flex
        v-else
        flex-direction="column"
        justify-content="space-between"
      >
        <c-text
          font-size="16px"
          font-weight="500"
          :color="constants.labelColor?.[step.color] || constants.labelColor.neutral"
          text-align="center"
          margin-bottom="12px"
        >
          {{ step.label }}
        </c-text>
        <c-box
          width="100%"
          height="8px"
          border-radius="20px"
          :background-color="constants.stepColor?.[step.color] || constants.stepColor.neutral"
        />
      </c-flex>
    </c-box>
  </c-grid>
</template>

<script>

export default {
  name: 'LineStep',
  props: {
    steps: {
      type: Array,
      default: () => [],
      // Example
      // steps: [
      //   { label: 'Langkah 1', color: 'primary', tooltip: 'hola' },
      //   { label: 'Langkah 2', color: 'danger', tooltip: 'hola' },
      //   { label: 'Langkah 3', color: 'neutral', tooltip: 'hola' },
      // ],
    },
  },
  computed: {
    constants() {
      return {
        labelColor: {
          primary: 'primary.400',
          danger: 'danger.400',
          neutral: 'neutral.lightGray',
        },
        stepColor: {
          primary: 'primary.400',
          danger: 'danger.400',
          neutral: 'neutral.superLightGray',
        },
      }
    },
  },
}
</script>
