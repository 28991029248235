<template>
  <c-box>
    <BaseButton
      variant="outlined"
      :is-disabled="!isValidAllForm || isDisabled"
      @click="onOpenDuplicate"
    >
      Duplikasi
    </BaseButton>
    <BaseModal
      :is-open="isOpenModalDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
      size="500px"
      overflow="visible"
    >
      <template #header>
        <c-flex
          width="100%"
          justify-content="center"
          padding="16px"
        >
          Duplikasi Hari Ke- {{ day }}
        </c-flex>
      </template>
      <template #body>
        <c-flex
          width="100%"
          flex-direction="column"
          padding-inline="16px"
          gap="12px"
        >
          <FormSelect
            v-model="targetDay"
            label="Tujuan Duplikasi"
            placeholder="Hari ke-"
            :options="_optionsTargetDay"
            is-without-margin-bottom
          />

          <c-flex
            width="100%"
            align-items="center"
            padding="8px 24px"
            gap="8px"
            background-color="neutral.superLightGray"
          >
            <c-box
              width="20px"
              height="20px"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-circle-warn.svg')"
                height="100%"
                width="100%"
                fill="#888888"
              />
            </c-box>
            <c-text
              font-size="14px"
              color="neutral.888888"
            >
              Hanya dapat memilih 3 waktu makan
            </c-text>
          </c-flex>

          <c-box
            position="relative"
          >
            <c-text
              font-size="16px"
              font-weight="500"
            >
              Jam Makan
            </c-text>
            <c-box
              v-for="key in Object.keys(mealTimes)"
              :key="key"
              border-top-width="1px"
              border-top-color="lightGray.900"
            >
              <c-flex
                v-chakra="{
                  ':hover .info': {
                    display: 'block',
                  }
                }"
                position="relative"
                width="100%"
                justify-content="space-between"
                align-items="center"
                padding="16px"
                :cursor="isLoadingData || mealTimes[key].isDisabled || mealTimes[key].isSkipped ? 'not-allowed' : 'pointer'"
                @click="onToggleMealTime(key)"
              >
                <c-box
                  v-if="targetDay && (mealTimes[key].isDisabled || mealTimes[key].isSkipped)"
                  class="info"
                  display="none"
                  position="absolute"
                  top="50%"
                  left="100%"
                  width="max-content"
                  max-width="120px"
                  transform="translateY(-50%)"
                  transition-duration="500ms"
                  background-color="neutral.darkGray"
                  color="neutral.white"
                  padding="4px 6px"
                  font-size="12px"
                  font-weight="500"
                  border-radius="4px"
                >
                  {{ mealTimes[key].isSkipped ? 'Jadwal ini diskip' : `Sudah terduplikasi hari ke- ${mealTimes[key].parentDay}` }}
                </c-box>
                <c-text
                  :color="mealTimes[key].isDisabled || mealTimes[key].isSkipped ? 'neutral.lightGray' : (mealTimes[key].isChecked ? 'primary.400' : 'neutral.superDarkGray')"
                  font-weight="500"
                >
                  {{ mealTimes[key].label }}
                </c-text>
                <c-checkbox
                  size="lg"
                  variant-color="primary"
                  :pointer-events="'none'"
                  :is-checked="mealTimes[key].isChecked"
                  :is-disabled="mealTimes[key].isDisabled || mealTimes[key].isSkipped"
                />
              </c-flex>
            </c-box>
            <c-flex
              v-if="isLoadingData"
              position="absolute"
              width="100%"
              height="100%"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              background-color="rgba(255,255,255,0.5)"
              top="0"
              right="0"
              left="0"
              bottom="0"
              gap="8px"
            >
              <c-spinner
                thickness="4px"
                speed="0.65s"
                empty-color="green.200"
                color="primary.400"
                size="xl"
              />
              <c-text color="primary.400">
                Memuat data duplikasi...
              </c-text>
            </c-flex>
          </c-box>
        </c-flex>
      </template>
      <template #footer>
        <c-grid
          width="100%"
          template-columns="repeat(2, 1fr)"
          padding="8px"
          gap="16px"
        >
          <BaseButton
            variant="outlined"
            :is-disabled="isLoadingSubmit"
            @click="onCloseDuplicate"
          >
            Batal
          </BaseButton>

          <BaseButton
            :is-disabled="isDisabledSubmit || isLoadingData"
            :is-loading="isLoadingSubmit"
            @click="onOpenConfirmDuplicate"
          >
            Simpan
          </BaseButton>
        </c-grid>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="!!isOpenConfirmDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah kamu yakin ingin menduplikasi jam makan hari-{{ day }} ke jam makan hari-{{ targetDay }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              :is-disabled="isLoadingSubmit"
              @click="onCloseConfirmDuplicate"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingSubmit"
              @click="onSubmitDuplicate"
            >
              Ya
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="!!isOpenErrorDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-warning.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            margin-top="8px"
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Duplikasi jam makan gagal
          </BaseText>
          <BaseText
            v-if="errorMessage"
            margin-top="16px"
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            {{ errorMessage }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px auto"
          text-align="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="isOpenErrorDuplicate = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="!!isOpenSuccessDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="150px"
            object-fit="cover"
            :src="require('@/assets/images/success-image.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            margin-top="8px"
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Duplikasi jam makan berhasil
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px auto"
          text-align="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="isOpenSuccessDuplicate = false"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  reqMenuRecommendation_Create,
  reqMenuRecommendation_MealPlanDetail,
  reqMenuRecommendation_GetDraft,
} from '@/requests/dietela-api/other'

export default {
  name: 'ModalDuplicateMenuRecommendation',
  components: {
    BaseButton,
    BaseText,
    BaseModal,
    FormSelect,
  },
  props: {
    isValidAllForm: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    optionsTargetDay: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'fetch-form-days',
  ],
  data() {
    return {
      isOpenModalDuplicate: false,
      isLoadingData: false,
      isLoadingSubmit: false,
      targetDay: null,
      duplicateData: {},
      mealPlanDetail: null,
      targetMenuRecommendation: null,
      mealTimes: {
        breakfast: {
          id: 'breakfast',
          label: 'Sarapan',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
        morningSnack: {
          id: 'morningSnack',
          label: 'Snack Pagi',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
        lunch: {
          id: 'lunch',
          label: 'Makan Siang',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
        afternoonSnack: {
          id: 'afternoonSnack',
          label: 'Snack Siang',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
        dinner: {
          id: 'dinner',
          label: 'Makan Malam',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
        nightSnack: {
          id: 'nightSnack',
          label: 'Snack Malam',
          isChecked: false,
          isDisabled: true,
          isSkipped: false,
          parentDay: null,
        },
      },
      isOpenConfirmDuplicate: false,
      isOpenSuccessDuplicate: false,
      isOpenErrorDuplicate: false,
      errorMessage: '',
    }
  },
  computed: {
    day() {
      return this.$route.query.day ?? 1
    },
    isDisabledSubmit() {
      return !this.targetDay
    },
    _optionsTargetDay() {
      if (this.optionsTargetDay.length > 0) {
        const days = this.optionsTargetDay.map((day) => {
          return {
            value: day,
            label: `Hari ke- ${day}`,
          }
        })
        return days.filter((it) => it.value != this.day)
      }
      return []
    },
    totalMealTimesChecked() {
      return Object.values(this.mealTimes).filter(it => it.isChecked).length
    },
    mealPlanSkipped() {
      if (this.mealPlanDetail && this.mealPlanDetail.length > 0) {
        return this.mealPlanDetail
          .filter((it) => it?.isSkipped)
          .map((it) => (it?.mealTime))
      }
      return []
    },
  },
  watch: {
    targetDay: {
      handler(val) {
        if (val) {
          this.getDuplicateData(val)
        } else {
          for (let key of Object.keys(this.mealTimes)) {
            this.mealTimes[key].isChecked = false
            this.mealTimes[key].isDisabled = true
            this.mealTimes[key].isSkipped = false
            this.mealTimes[key].parentDay = null
          }
        }
      },
    },
  },
  methods: {
    onOpenDuplicate() {
      if (!this.isValidAllForm || this.isDisabled) return
      this.isOpenModalDuplicate = true

    },
    onCloseDuplicate() {
      if (!this.isLoadingSubmit) {
        this.isOpenModalDuplicate = false
        this.targetDay = null
        this.duplicateData = {}
        this.mealPlanDetail = null
        this.targetMenuRecommendation = null
      }
    },
    async getDuplicateData(targetDay) {
      try {
        this.isLoadingData = true
        const [
          duplicateDate,
          mealPlanDetail,
          targetMenuRecommendation,
        ] = await Promise.all([
          reqNutriMealPlan.getMenuRecommendationDuplicationData(this.$store.getters.axios, {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
            parentDay: this.day,
            targetDay: targetDay,
          }),
          reqMenuRecommendation_MealPlanDetail(this.$store.getters.axios, {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
          }),
          reqMenuRecommendation_GetDraft(this.$store.getters.axios, {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
            day: targetDay,
          }),
        ])

        this.duplicateData = duplicateDate?.data?.data || {}
        this.mealPlanDetail = mealPlanDetail?.data?.data || null
        this.targetMenuRecommendation = targetMenuRecommendation?.data?.data || null

        for (let key of Object.keys(this.mealTimes)) {
          if (this.duplicateData && Object.keys(this.duplicateData).length > 0 && Object.prototype.hasOwnProperty.call(this.duplicateData, key)) {
            if (this.duplicateData[key].parentDay == this.day) {
              this.mealTimes[key].isChecked = this.duplicateData[key].isChecked
              this.mealTimes[key].isDisabled = false
              this.mealTimes[key].parentDay = this.duplicateData[key].isEdited === 0 ? this.duplicateData[key].parentDay : null 
            } else {
              this.mealTimes[key].isChecked = false
              this.mealTimes[key].isDisabled = this.duplicateData[key].isEdited === 0 ? true : false
              this.mealTimes[key].parentDay = this.duplicateData[key].isEdited === 0 ? this.duplicateData[key].parentDay : null
            }
          } else {
            this.mealTimes[key].isChecked = false
            this.mealTimes[key].isDisabled = false
            this.mealTimes[key].parentDay = null
          }

          const isSkipped = this.mealPlanSkipped.some((it) => it === key)
          if (isSkipped) {
            this.mealTimes[key].isSkipped = true
          } else {
            this.mealTimes[key].isSkipped = false
          }
        }
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoadingData = false
      }
    },
    onToggleMealTime(key) {
      if (!this.mealTimes[key].isDisabled && !this.mealTimes[key].isSkipped) {
        if (this.mealTimes[key].isChecked) {
          this.mealTimes[key].isChecked = false
        } else {
          if (this.totalMealTimesChecked < 3) {
            this.mealTimes[key].isChecked = true
          } else {
            this.$toast({
              title: 'Failed',
              description: 'Can only choose 3 meal times',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
            })
          }
        }
      }
    },
    onOpenConfirmDuplicate() {
      this.isOpenConfirmDuplicate = true
    },
    onCloseConfirmDuplicate() {
      if (!this.isLoadingData && !this.isLoadingSubmit) {
        this.isOpenConfirmDuplicate = false
      }
    },
    async onSubmitDuplicate() {
      if (!this.isLoadingData && !this.isLoadingSubmit) {
        try {
          const payload = {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
            parentDay: this.day,
            targetDay: Number(this.targetDay),
            mealTimes: Object.keys(this.mealTimes)
              .map((key) => this.mealTimes[key])
              .filter((it) => it.isChecked)
              .map((it) => (it.id))
              .join(','),
          }

          this.isLoadingSubmit = true
          await reqNutriMealPlan.postMenuRecommendationDuplicate(this.$store.getters.axios, { payload })

          let mealTimesFilter = {}
          for (let key in this.mealTimes) {
            if (this.mealTimes[key].isChecked === false && this.mealTimes[key].isDisabled === false && this.mealTimes[key].isSkipped === false) {
              mealTimesFilter[key] = this.mealTimes[key]
            }
          }

          let targetDayValidation = 1
          if (Object.keys(mealTimesFilter).length > 0) {
            for (let key in mealTimesFilter) {
              if (!this.targetMenuRecommendation[key] || this.targetMenuRecommendation[key]?.validation === -1 || (this.targetMenuRecommendation[key]?.duplicationDetail?.parentDay == this.day && this.targetMenuRecommendation[key]?.duplicationDetail?.isEdited === 0)) {
                targetDayValidation = -1
              }
            }
          }

          let payloadSetValidation = {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
            isDraft: 1,
            isSaveDraf: 0,
            day: Number(this.targetDay),
            validation: targetDayValidation,
          }
          await reqMenuRecommendation_Create(this.$store.getters.axios, payloadSetValidation)
          this.$emit('fetch-form-days')

          this.isOpenSuccessDuplicate = true
        } catch (err) {
          this.isOpenErrorDuplicate = true
          this.errorMessage = err?.response?.data?.message
        } finally {
          this.isLoadingSubmit = false
          this.onCloseConfirmDuplicate()
          this.onCloseDuplicate()
        }
      }
    },
  },
}
</script>