var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('c-box', {
    attrs: {
      "as": "nav",
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "primary",
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "is-disabled": _vm.page <= 1
    },
    on: {
      "click": _vm.handlePrevPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _c('c-box', {
    attrs: {
      "as": "ul",
      "display": "flex",
      "list-style-type": "none",
      "gap": "16px"
    }
  }, _vm._l([].concat(_vm.firstPagination, _vm.middlePagination, _vm.lastPagination), function (i) {
    return _c('c-box', {
      key: i === '...' ? Math.random() : i,
      attrs: {
        "as": "li"
      }
    }, [i === '...' ? _c('c-box', {
      attrs: {
        "height": "24px",
        "width": "24px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(i) + " ")]) : _c('c-button', {
      attrs: {
        "variant": _vm.page === i ? 'outline' : 'ghost',
        "variant-color": "primary",
        "px": "0",
        "py": "4px",
        "w": "32px",
        "h": "32px",
        "mx": "4px"
      },
      on: {
        "click": function click($event) {
          return _vm.handlePageClick(i);
        }
      }
    }, [_vm._v(" " + _vm._s(i) + " ")])], 1);
  }), 1), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "variant-color": "primary",
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "is-disabled": _vm.page >= _vm.totalPage
    },
    on: {
      "click": _vm.handleNextPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }