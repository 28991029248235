var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "is-disabled": !_vm.isValidAllForm || _vm.isDisabled
    },
    on: {
      "click": _vm.onOpenDuplicate
    }
  }, [_vm._v(" Duplikasi ")]), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "500px",
      "overflow": "visible"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "center",
            "padding": "16px"
          }
        }, [_vm._v(" Duplikasi Hari Ke- " + _vm._s(_vm.day) + " ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "padding-inline": "16px",
            "gap": "12px"
          }
        }, [_c('FormSelect', {
          attrs: {
            "label": "Tujuan Duplikasi",
            "placeholder": "Hari ke-",
            "options": _vm._optionsTargetDay,
            "is-without-margin-bottom": ""
          },
          model: {
            value: _vm.targetDay,
            callback: function callback($$v) {
              _vm.targetDay = $$v;
            },
            expression: "targetDay"
          }
        }), _c('c-flex', {
          attrs: {
            "width": "100%",
            "align-items": "center",
            "padding": "8px 24px",
            "gap": "8px",
            "background-color": "neutral.superLightGray"
          }
        }, [_c('c-box', {
          attrs: {
            "width": "20px",
            "height": "20px"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-circle-warn.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#888888"
          }
        })], 1), _c('c-text', {
          attrs: {
            "font-size": "14px",
            "color": "neutral.888888"
          }
        }, [_vm._v(" Hanya dapat memilih 3 waktu makan ")])], 1), _c('c-box', {
          attrs: {
            "position": "relative"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "16px",
            "font-weight": "500"
          }
        }, [_vm._v(" Jam Makan ")]), _vm._l(Object.keys(_vm.mealTimes), function (key) {
          return _c('c-box', {
            key: key,
            attrs: {
              "border-top-width": "1px",
              "border-top-color": "lightGray.900"
            }
          }, [_c('c-flex', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                ':hover .info': {
                  display: 'block'
                }
              },
              expression: "{\n                ':hover .info': {\n                  display: 'block',\n                }\n              }"
            }],
            attrs: {
              "position": "relative",
              "width": "100%",
              "justify-content": "space-between",
              "align-items": "center",
              "padding": "16px",
              "cursor": _vm.isLoadingData || _vm.mealTimes[key].isDisabled || _vm.mealTimes[key].isSkipped ? 'not-allowed' : 'pointer'
            },
            on: {
              "click": function click($event) {
                return _vm.onToggleMealTime(key);
              }
            }
          }, [_vm.targetDay && (_vm.mealTimes[key].isDisabled || _vm.mealTimes[key].isSkipped) ? _c('c-box', {
            staticClass: "info",
            attrs: {
              "display": "none",
              "position": "absolute",
              "top": "50%",
              "left": "100%",
              "width": "max-content",
              "max-width": "120px",
              "transform": "translateY(-50%)",
              "transition-duration": "500ms",
              "background-color": "neutral.darkGray",
              "color": "neutral.white",
              "padding": "4px 6px",
              "font-size": "12px",
              "font-weight": "500",
              "border-radius": "4px"
            }
          }, [_vm._v(" " + _vm._s(_vm.mealTimes[key].isSkipped ? 'Jadwal ini diskip' : "Sudah terduplikasi hari ke- ".concat(_vm.mealTimes[key].parentDay)) + " ")]) : _vm._e(), _c('c-text', {
            attrs: {
              "color": _vm.mealTimes[key].isDisabled || _vm.mealTimes[key].isSkipped ? 'neutral.lightGray' : _vm.mealTimes[key].isChecked ? 'primary.400' : 'neutral.superDarkGray',
              "font-weight": "500"
            }
          }, [_vm._v(" " + _vm._s(_vm.mealTimes[key].label) + " ")]), _c('c-checkbox', {
            attrs: {
              "size": "lg",
              "variant-color": "primary",
              "pointer-events": 'none',
              "is-checked": _vm.mealTimes[key].isChecked,
              "is-disabled": _vm.mealTimes[key].isDisabled || _vm.mealTimes[key].isSkipped
            }
          })], 1)], 1);
        }), _vm.isLoadingData ? _c('c-flex', {
          attrs: {
            "position": "absolute",
            "width": "100%",
            "height": "100%",
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "center",
            "background-color": "rgba(255,255,255,0.5)",
            "top": "0",
            "right": "0",
            "left": "0",
            "bottom": "0",
            "gap": "8px"
          }
        }, [_c('c-spinner', {
          attrs: {
            "thickness": "4px",
            "speed": "0.65s",
            "empty-color": "green.200",
            "color": "primary.400",
            "size": "xl"
          }
        }), _c('c-text', {
          attrs: {
            "color": "primary.400"
          }
        }, [_vm._v(" Memuat data duplikasi... ")])], 1) : _vm._e()], 2)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(2, 1fr)",
            "padding": "8px",
            "gap": "16px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onCloseDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "is-disabled": _vm.isDisabledSubmit || _vm.isLoadingData,
            "is-loading": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onOpenConfirmDuplicate
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenConfirmDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah kamu yakin ingin menduplikasi jam makan hari-" + _vm._s(_vm.day) + " ke jam makan hari-" + _vm._s(_vm.targetDay) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onCloseConfirmDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onSubmitDuplicate
          }
        }, [_vm._v(" Ya ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenErrorDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-warning.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "margin-top": "8px",
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Duplikasi jam makan gagal ")]), _vm.errorMessage ? _c('BaseText', {
          attrs: {
            "margin-top": "16px",
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px auto",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenErrorDuplicate = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenSuccessDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/success-image.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "margin-top": "8px",
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Duplikasi jam makan berhasil ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px auto",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenSuccessDuplicate = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }