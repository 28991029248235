<template>
  <c-box
    margin-top="24px"
  >
    <Tabs
      active-tab="recommendation"
      mode="edit"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="_tabsRealTime"
    >
      <c-box
        v-if="!isLoadingDraft"
      >
        <FormMenuRecommendation
          ref="formMealPlan"
          :draft="draft"
          @update:validation="onChangeValidation"
        />
      </c-box>
      <c-box
        v-else
        text-align="center"
        margin-block="36px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Menu Rekomendasi...
        </c-text>
      </c-box>
    </Tabs>

    <ModalSimpleInfo
      :is-open="showModalConfirm"
      :image="require('@/assets/images/image-question.svg')"
      title="Data belum tersimpan, yakin mau keluar halaman?"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingSave"
            @click="onCloseModalConfirm"
          >
            Tidak
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingSave"
            @click="onSubmitModalConfirm"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="!true"
      :image="require('@/assets/images/image-question.svg')"
      title="Apakah kamu yakin ingin keluar halaman?"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingSave"
            @click="onCloseModalConfirm"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingSave"
            @click="onSubmitModalConfirm"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import BaseButton from '@/components/elements/base-button.vue'
import {
  tabs, tabsMealPlan,
} from '@/constants/meal-plans'
import { mapGetters, mapMutations } from 'vuex'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { CFlex } from '@chakra-ui/vue'
import FormMenuRecommendation
  from '@/components/meal-plan/_widgets/form-menu-recommendation.vue'
import { reqMenuRecommendation_GetDraft } from '@/requests/dietela-api/other'
import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'

export default {
  components: {
    FormMenuRecommendation,
    CFlex,
    ModalSimpleInfo,
    BaseButton,
    Tabs,
  },
  mixins: [mixinsTabsMealPlan],
  beforeRouteLeave(to, from, next) {
    if (this.to || to.params?.bypass || this.saveState || !this.isAuthenticated) {
      next()
    } else {
      this.to = to
      this.showModalConfirm = true
    }
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      to: null,
      showModalConfirm: false,
      draft: null,
      isLoadingDraft: true,
      isLoadingSave: false,
      dataPreview: {
        optConditions: {},
      },
      validation: 1,
    }
  },
  computed: {
    ...mapGetters({
      saveState: 'general/saveState',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    tab() {
      return this.$route.query?.tab ?? this.constants.tabsMealPlan['meal-plan']
    },
    constants() {
      return {
        tabs,
        tabsMealPlan,
      }
    },
    _tabsRealTime() {
      return [
        { 
          ...this._tabs[0],
        },
        { 
          ...this._tabs[1],
        },
        {
          ...this._tabs[2],
          validation: this.validation,
        },
      ]
    },
  },
  watch: {
    $route: {
      handler() {
        this.init()
        this.mealPlanPreview()
      },
      deep: true,
      immediate: true,
    },
    isAuthenticated: {
      immediate: true,
      handler(val) {
        if (!val) {
          window.removeEventListener('beforeunload', this.onPreventNav)
          this.$router.replace({
            name: 'auth',
            params: {
              bypass: 1,
            },
            query: {
              redirect: this.$route.path,
            },
          })
        }
      },
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.onPreventNav)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onPreventNav)
  },
  created() {
    this.verifyCurrentTab()
  },
  methods: {
    ...mapMutations({
      setNutritionalConditions: 'mealPlan/setNutritionalConditions',
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
    }),
    verifyCurrentTab() {
      this.$router.replace({
        name: 'nutri.meal-plan.menu-recommendation.edit',
        params: this.$route.params,
        query: {
          day: this.$route.query?.day || 1,
          mealTime: this.$route.query?.mealTime || 'breakfast',
        },
      }).catch(() => {})
    },
    async init() {
      try {
        this.isLoadingDraft = true
        this.draft = {}
        const resDraft = await reqMenuRecommendation_GetDraft(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          day: this.$route.query.day,
        })
        const dataDraft = resDraft.data?.data
        if (dataDraft) {
          this.draft = dataDraft
        }
      } catch (err) {
        //
      } finally {
        this.isLoadingDraft = false
      }
    },
    onCloseModalConfirm() {
      if (!this.isLoadingSave) {
        this.showModalConfirm = false
        this.to = null
      }
    },
    async onSubmitModalConfirm() {
      this.isLoadingSave = true
      try {
        await this.$refs.formMealPlan.onSaveDraft()
      } finally {
        this.isLoadingSave = false
        this.$router.push(this.to)
      }
    },
    onPreventNav(event) {
      if (this.statusSave) return
      event.preventDefault()
      event.returnValue = ''
    },
    async mealPlanPreview() {
      const res = await reqNutritionist_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.setMealPlanPreview(res?.data?.data)
    },
    onChangeValidation(value) {
      this.validation = value?.validation
    },
  },
}
</script>
