var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "700px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "position": "relative"
          }
        }, [_c('c-flex', {
          attrs: {
            "width": "100%",
            "padding": "16px",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-button', {
          attrs: {
            "variant": "ghost",
            "position": "absolute",
            "right": "12px",
            "top": "8px",
            "width": "40px",
            "height": "40px"
          },
          on: {
            "click": function click() {
              return _vm.$emit('change:is-open', false);
            }
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "close",
            "size": "20px"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "position": "relative",
            "flex-direction": "column",
            "justify-content": "center",
            "text-align": "center"
          }
        }, [_c('c-box', {
          attrs: {
            "margin-inline": "24px",
            "padding-bottom": "8px"
          }
        }, [_c('c-form-control', {
          attrs: {
            "width": "100%",
            "pos": "relative"
          }
        }, [_c('c-image', {
          attrs: {
            "src": require('@/assets/ic-search.svg'),
            "alt": "icon",
            "position": "absolute",
            "top": "50%",
            "transform": "translateY(-50%)",
            "left": "15px",
            "height": "24px",
            "z-index": "2"
          }
        }), _c('c-input', {
          attrs: {
            "id": "search",
            "type": "text",
            "w": "100%",
            "height": "48px",
            "placeholder": "Pencarian",
            "border-radius": "20px",
            "padding-left": "50px",
            "position": "relative",
            "font-size": ['14px', '16px'],
            "z-index": "1"
          },
          model: {
            value: _vm.temporaryFilterSearch,
            callback: function callback($$v) {
              _vm.temporaryFilterSearch = $$v;
            },
            expression: "temporaryFilterSearch"
          }
        })], 1)], 1), !_vm.isLoadingDataFood ? _c('c-box', {
          attrs: {
            "max-height": "380px",
            "height": "100%",
            "overflow-y": "auto",
            "padding-block": "8px",
            "padding-inline": "24px"
          }
        }, [_vm.options.length > 0 ? _c('c-box', [_c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(5, 1fr)",
            "gap": "8px"
          }
        }, _vm._l(_vm.options, function (food, i) {
          var _vm$temporarySelected, _vm$temporarySelected2, _vm$temporarySelected3, _vm$temporarySelected4;

          return _c('c-flex', {
            key: i,
            attrs: {
              "position": "relative",
              "flex-direction": "column",
              "justify-content": "flex-start",
              "align-items": "center",
              "width": "100%",
              "padding": "16px",
              "border-radius": "12px",
              "_hover": {
                backgroundColor: ((_vm$temporarySelected = _vm.temporarySelectedFood) === null || _vm$temporarySelected === void 0 ? void 0 : _vm$temporarySelected.id) === (food === null || food === void 0 ? void 0 : food.id) ? 'primary.400' : 'neutral.superLightGray'
              },
              "background-color": ((_vm$temporarySelected2 = _vm.temporarySelectedFood) === null || _vm$temporarySelected2 === void 0 ? void 0 : _vm$temporarySelected2.id) === (food === null || food === void 0 ? void 0 : food.id) ? 'primary.400' : 'white',
              "cursor": "pointer"
            },
            on: {
              "click": function click() {
                return _vm.temporarySelectedFood = food;
              }
            }
          }, [_c('c-box', [_c('c-image', {
            attrs: {
              "src": food === null || food === void 0 ? void 0 : food.photoUrl,
              "width": "72px",
              "height": "72px",
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px",
              "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)",
              "margin-bottom": "8px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": ((_vm$temporarySelected3 = _vm.temporarySelectedFood) === null || _vm$temporarySelected3 === void 0 ? void 0 : _vm$temporarySelected3.id) === (food === null || food === void 0 ? void 0 : food.id) ? 'white' : 'unset'
            }
          }, [_vm._v(" " + _vm._s(food === null || food === void 0 ? void 0 : food.foodName) + " ")]), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": ((_vm$temporarySelected4 = _vm.temporarySelectedFood) === null || _vm$temporarySelected4 === void 0 ? void 0 : _vm$temporarySelected4.id) === (food === null || food === void 0 ? void 0 : food.id) ? 'white' : 'neutral.888888'
            }
          }, [_vm._v(" " + _vm._s(food === null || food === void 0 ? void 0 : food.servingSizePerPortion) + " " + _vm._s(food === null || food === void 0 ? void 0 : food.servingSizeUnitPerPortion) + " ")])], 1);
        }), 1), _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "w": "100%",
            "margin-top": "16px"
          }
        }, [_c('BasePagination2', {
          attrs: {
            "page": _vm.filter.page,
            "total-page": _vm.pages.length
          },
          on: {
            "on-change": function onChange(currentPage) {
              return _vm.$emit('change:filter-page', currentPage);
            }
          }
        })], 1)], 1) : _c('c-flex', {
          attrs: {
            "width": "100%",
            "min-height": "100px",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "18px",
            "font-weight": "500",
            "color": "danger.400"
          }
        }, [_vm._v(" Data tidak ditemukan ")])], 1)], 1) : _c('c-box', {
          attrs: {
            "text-align": "center",
            "margin-block": "36px"
          }
        }, [_c('c-spinner', {
          attrs: {
            "thickness": "4px",
            "speed": "0.65s",
            "empty-color": "green.200",
            "color": "primary.400",
            "size": "xl"
          }
        }), _c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-weight": "500"
          }
        }, [_vm._v(" Memuat data... ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        var _vm$temporarySelected5;

        return [_c('c-flex', {
          attrs: {
            "gap": "8px",
            "width": "100%",
            "align-items": "center",
            "justify-content": "center",
            "padding-top": "8px",
            "padding-left": "16px",
            "padding-right": "16px",
            "margin-bottom": "30px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "flex-grow": "1",
            "rounded": "1000px",
            "width": "100%",
            "max-width": "400px",
            "disabled": !((_vm$temporarySelected5 = _vm.temporarySelectedFood) !== null && _vm$temporarySelected5 !== void 0 && _vm$temporarySelected5.id)
          },
          on: {
            "click": function click() {
              return _vm.$emit('change:selected-food', _vm.temporarySelectedFood);
            }
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }