<template>
  <c-box
    background-color="neutral.superLightGray"
    border-bottom="1px solid"
    border-color="neutral.lightGray"
    padding="16px"
    width="100%"
  >
    <c-flex justify-content="space-between">
      <c-text
        font-size="18px"
        font-weight="500"
      >
        {{ mealType }}
      </c-text>
      <c-text
        font-size="16px"
      >
        {{ mealPortion }} {{ mealPortionUnit }}
      </c-text>
    </c-flex>
    <c-text
      margin-top="16px"
      color="neutral.888888"
      font-size="16px"
      margin-right="auto"
    >
      Pilihan golongan makanan
    </c-text>
    <c-grid
      margin-top="8px"
      template-columns="repeat(auto-fill, minmax(75px, 1fr))"
      gap="4px"
    >
      <MealPortion
        v-for="(meal, mealIdx) in mealItems"
        :key="mealIdx"
        :image="meal?.photoUrl || require('@/assets/images/image-gray.png')"
        :name="meal?.foodName || '-'"
        :serving-size="calcServingSize(meal)"
        :serving-size-unit="meal?.householdMeasurementUnitPerPortion || meal?.householdMeasurementUnit || ''"
      />
    </c-grid>
  </c-box>
</template>

<script>
import MealPortion from '@/components/meal-portion.vue'
import { CFlex } from '@chakra-ui/vue'
import { convertToFraction } from '@/utils/fraction'

export default {
  name: 'CardMealPortionGuide',
  components: { CFlex, MealPortion },
  props: {
    mealType: {
      type: [String, Number],
      required: true,
    },
    mealPortion: {
      type: [String, Number],
      required: true,
    },
    mealPortionDecimal: {
      type: [String, Number],
      required: true,
    },
    mealPortionUnit: {
      type: String,
      required: true,
    },
    mealItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    convertToFraction,
    calcServingSize(meal) {
      if (this.mealPortion && (meal?.householdMeasurementPerPortion || meal?.householdMeasurement)) {
        let size = meal?.householdMeasurementPerPortion ?? meal?.householdMeasurement
  
        const parseSize = size ? parseFloat(size) : 0
        if (this.mealPortionDecimal) {
          const result = parseFloat(this.mealPortionDecimal) * parseSize
    
          if (Number.isInteger(result)) {
            return result
          }
    
          const formattedNumber = result
          return this.convertToFraction(formattedNumber)
        }
        return parseSize
      }
      return ''
    },
  },
}
</script>
